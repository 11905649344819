<template>
  <div>
    <el-row class="ShopImg">
      <el-col
        :span="7"
        v-if="!msg.product"
        @click.native="jumpDetail(msg)"
      >
        <img
          :alt="msg.goodsName | defaultName"
          :src="msg.goodsPic.split(',')[0] | defaultImage"
        />
      </el-col>
      <el-col
        :span="7"
        v-if="msg.product"
        @click.native="jumpDetail(msg)"
      >
        <img  v-if="!msg.product.publishStatus" class="soldOut" alt="已下架" src="@/assets/Shelves.png" />
        <img
          :alt="msg.product.name | defaultName"
          :src="msg.product.pic.split(',')[0] | defaultImage"
        />

        <!-- <p class="stockTotal" v-if="msg.product.stockTotal<1000 && msg.product.stockTotal>Number(msg.product.minOrderNum)">剩余{{ msg.product.stockTotal }}{{ msg.product.unit? msg.product.unit:'套' }}</p> -->
      </el-col>
      <el-col :span="16" v-if="!msg.product" class="otherInfo">
        <h3>
          {{ msg.goodsName }}
          <!-- 商品类型（0. 普通 1. 赠品 3. 活动 4.A+B A 5.A+B B 6.套餐 7.团购 8.预售 9.限时购） -->
          <span class="gift">{{
                msg.goodsType == 0
                ? "【普通商品】"
                : msg.goodsType == 1
                ? "【赠品】"
                : msg.goodsType == 2 || msg.goodsType == 11
                ? "【奖品】"
                : msg.goodsType == 3
                ? "【活动商品】"
                :  msg.goodsType == 4
                ? "【(A)】"
                :  msg.goodsType == 5
                ? "【(B)】"
                : msg.goodsType == 6
                ? "【套餐】"
                : msg.goodsType == 7
                ? "【团购】"
                :msg.goodsType == 8
                ? "【预售】"
                : msg.goodsType == 9
                ? "【限时购】"
                : ""
          }}</span>
            <span class="gift" v-if="msg.isNoBuy == 1">
            【未购】
          </span>

        </h3>
        
        <el-col  
          ><span>药品规格：</span>
          <!-- {{ msg.partSpecification }} -->
          <span>{{ msg.specification }}</span
          ></el-col
        >
        <el-col >
          <span>件装规格：</span>
          <span>
            {{ msg.partSpecification }} 
          </span>
        </el-col>
        <el-col 
          ><span>效期优于：</span><span>{{ msg.validDate }}</span></el-col
        >
      </el-col>

      <el-col :span="16" v-if="msg.product" class="otherInfo">
        <h3>
          {{ msg.product.name }} 
          <span class="gift">{{
                msg.cartType == 1
                ? "【套餐】"
                :msg.cartType == 2
                ? "【预售】"
                : ""
          }}</span>
          <span class="gift" v-if="msg.isNoBuy == 1">【未购】</span>
          <span class="gift" v-if="msg.product.isLimit == 1">{{msg.product.isLimit==1?'【限购'+msg.purchaseLimitationNum+msg.product.unit+'】':''}}</span>
          <!-- v-if="msg.status === 0" -->
          <span class="NOStocks "  v-if="msg.cartType!== 2&&msg.product.stockTotal< Number(msg.product.minOrderNum)" >(库存不足)</span>
          <el-popover
            v-if="msg.coupon.hasCoupon === 1"
            placement="right"
            width="300"
            trigger="manual"
            v-model="visible"
          >
            <p class="titHead">{{ msg.product.name }}可用优惠券</p>
            <p class="titMsg">(领取后可用于购物车商品)</p>
            <el-col
              class="coupon"
              :span="24"
              v-for="(item, index) in msg.coupon.couponList"
              :key="index"
            >
              <el-col :span="20" class="coupon-left">
                <div class="coupondes">
                  <div class="coupondesTit">
                    <div class="numWraper">
                      ￥<span class="num">{{ item.amount }}</span>
                      <span v-if="item.minPrice > 0" class="numMsg"
                        >满{{ item.minPrice }}元可用</span
                      >
                      <span v-if="item.minSkuNum > 0" class="numMsg"
                        >满{{ item.minSkuNum }}个品种可用</span
                      >
                    </div>
                  </div>
                  <div class="coupondesMsg">{{ item.couponName }}</div>
                  <div class="date">{{ item.beginDate }}至{{ item.endDate }}</div>
                </div>
              </el-col>
              <el-col
                :span="4"
                class="text-center coupon-right"
                @click.native="getCouponFun(item.couponId)"
              >
                立即领取
              </el-col>
              <!-- <el-col :span="10" class="coupon-left">
                <div class="numWraper">
                  ￥<span class="num">{{ item.amount }}</span>
                </div>
                <div v-if="item.minPrice > 0" class="numMsg">
                  满{{ item.minPrice }}元可用
                </div>
                <div v-if="item.minSkuNum > 0" class="numMsg">
                  满{{ item.minSkuNum }}个品种方可使用
                </div>
              </el-col>
              <el-col :span="14" class="text-center coupon-right">
                <div class="coupondes">
                  <p>
                    <span>名称：</span><span class="coupondesMSG">{{ item.couponName }}</span>
                  </p>
                </div>
                <div class="date">
                  ({{ item.beginDate }}至{{ item.endDate }})
                </div>
                <div class="GetBtn">
                    <span  @click="getCouponFun(item.couponId)">立即领取</span>
                  </div>
              </el-col> -->
            </el-col>

            <!-- <div class="clear"></div> -->
            <el-button
              class="GetCoupon"
              slot="reference"
              @click="visible = !visible"
              >领取优惠券</el-button
            >
          </el-popover>
        </h3>
        <el-col  v-if="msg.product.specification!=''">
          <span>药品规格：</span>
          <!-- {{ msg.product.partSpecification }} -->
          <span>
            {{ msg.product.specification }} 
          </span>
        </el-col>
        <el-col v-if="msg.product.partSpecification!=''">
          <span>件装规格：</span>
          <span>
            {{ msg.product.partSpecification }} 
          </span>
        </el-col>
        <el-col  v-if="msg.product.validDateFromat!=''">
          <span>效期优于：</span> <span> {{ msg.product.validDateFromat }} </span>
        </el-col>
        <el-col  v-if="proSec!=''">
          <span>{{proSec}}</span>
        </el-col>
        <el-col><span v-show="msg.giftFlag" class="gift">赠品</span></el-col>
        <el-col>
          <span class="itemSecBox" v-if="itemSec!=''">{{itemSec}}</span>
        </el-col>
        <!-- <el-dialog
          v-if="msg.coupon.hasCoupon === 1"
          center
          :title=" + '可用优惠券'"
          :visible.sync="dialogVisible"
          width="25%"
        >
        
        </el-dialog> -->
      </el-col>
    </el-row>
    <div class="numPriceMsg"  v-if="numPriceArr && numPriceArr!=''">
      <span class="numPricLaber">特价活动</span> 
      {{ numPricMsg }}
    </div>
  </div>
</template>

<script>
import { GetCoupon } from "api/carToOrder.js";
export default {
  name: "ShopImg",
  inject: ["reload"],
  data() {
    return {
      visible: false,
      proSec:'',
       itemSec:'',
      // dialogVisible: false, //初始弹框不出现
      numPriceArr:[],
      numPricMsg:'',
    };
  },
  props: {
    msg: {
      type: Object,
    },
      Datas:{
       type:Array
    },
    num:{
      type:Number
    }
  },
  computed:{
    ProQuantity(){
         return this.$store.state.goods[this.num]
       } 
    },
    watch:{
      'Datas':function(){
        if(this.Datas.length>0){
            this.Datas.forEach(item=>{
              if(this.msg.product){
                  if(item.couponSingleGoodsDTO.goodsId==this.msg.productId){
                    if(Number(item.purchaseAmount).toFixed(2)==0){
                      this.itemSec=''
                    }else{
                      this.itemSec='还差'+Number(item.purchaseAmount).toFixed(2)+'元，可减'+item.couponSingleGoodsDTO.couponInfo.promotionValue+'元'
                    }
                    // this.itemSec='还差'+Number(item.purchaseAmount).toFixed(2)+'元，可减'+item.couponSingleGoodsDTO.couponInfo.promotionValue+'元'
                  }
              } else{
                 this.itemSec=''
              }
              
             })
        }else{
           this.itemSec=''
        }
         
      },
       // // watch 可以监听 data 数据 也可以监听 全局 vuex数据
       "ProQuantity": {
      //深度监听，可监听到对象、数组的变化   newValue
        handler(newValue) {
          this.desFun(newValue)
        },
        deep: true, //true 深度监听
      },


  },
  methods: {
    // 手动领取优惠券
    getCouponFun(couponId) {
      GetCoupon(couponId).then((data) => {
        if (data.data.code == 200) {
          this.$message({
            message: data.data.msg,
            type: "success",
          });
          this.reload();
        } else {
          this.$message({
            message: data.data.msg,
            type: "warning",
          });
        }
      });
    },
    jumpDetail(msg) {
      if(!msg.product){
        // alert("fghj"+msg.goodsType)
        if(msg.goodsType===6){
        // 跳套餐详情页
           this.$router.push({
              name: "SetMealDetail",
              query: { promotionId: msg.goodsId},
            });
          }else{
              this.$router.push({ name: "ProductDetail", query: { id: msg.goodsId ,isRecentExpiration:msg.isRecentExpiration } });
          }
        // console.log(msg)
      }else{
          if(!msg.product.publishStatus){
              this.$message({
                message:'该商品已下架',
                type: "warning",
              });
          }else{
            // alert(msg.product.cartType)
            if(msg.product.cartType==1){
              // 跳转套餐详情
              this.$router.push({
                name: "SetMealDetail",
                query: { promotionId: msg.productId},
              });
            }else{
              this.$router.push({ name: "ProductDetail", query: { id: msg.productId ,isRecentExpiration:msg.isRecentExpiration } });
            }
          }
      }
    },
    desFun(ProDetail){
      this.numPriceArr=[]
      this.numPricMsg=''
      if(ProDetail.product.numPrice && ProDetail.product.numPrice!=''){
          this.numPriceArr=JSON.parse(ProDetail.product.numPrice)
          console.log(this.numPriceArr)
          if(ProDetail.quantity<this.numPriceArr[0].num){
            this.numPricMsg='再选'+Number(this.numPriceArr[0].num - ProDetail.quantity)+'盒，可减'+(Number(ProDetail.product.memberPrice-this.numPriceArr[0].price)*this.numPriceArr[0].num).toFixed(2)+'元'
          }else{
            if( this.numPriceArr.length>1){
              this.numPriceArr.forEach((item,index)=>{
                  if(ProDetail.quantity>=item.num){
                    if(index<this.numPriceArr.length-1){
                        if(Number(this.numPriceArr[index+1].num - ProDetail.quantity)<0){
                          this.numPricMsg='已选'+ProDetail.quantity+'盒，已减'+(Number(ProDetail.product.memberPrice-item.price)*ProDetail.quantity).toFixed(2)+'元'
                        }else{
                          this.numPricMsg='已选'+ProDetail.quantity+'盒，已减'+(Number(ProDetail.product.memberPrice-item.price)*ProDetail.quantity).toFixed(2)+'元，再选'+Number(this.numPriceArr[index+1].num - ProDetail.quantity)+'盒，可减'+(Number(ProDetail.product.memberPrice-this.numPriceArr[index+1].price)*this.numPriceArr[index+1].num).toFixed(2) +'元'
                        }
                      }else{
                      this.numPricMsg='已选'+ProDetail.quantity+'盒，已减'+(Number(ProDetail.product.memberPrice-item.price)*ProDetail.quantity).toFixed(2)+'元'
                    }
                  }else if(ProDetail.quantity==item.num && index>=this.numPriceArr.length-1){
                    this.numPricMsg='已选'+ProDetail.quantity+'盒，已减'+(Number(ProDetail.product.memberPrice-item.price)*ProDetail.quantity).toFixed(2)+'元'
                  }
                }
              )
            }else{
              if(ProDetail.quantity>=this.numPriceArr[0].num){
                this.numPricMsg='已选'+ProDetail.quantity+'盒，已减'+(Number(this.msg.product.memberPrice-this.numPriceArr[0].price)*ProDetail.quantity).toFixed(2)+'元'
              }
            }
          }
      }else{
        return false
      }
    }
  },
  created() {
    this.desFun(this.msg)
    if (this.msg.product) {
        this.msg.product.comboProducts.forEach(item=>{
          this.proSec+=item.name+item.minOrderNum+item.unit+','
        })
        this.proSec=this.proSec.substring(0,this.proSec.length-1)
      if (this.msg.cartType!== 2 && this.msg.product.stockTotal < 0) {
        this.$message({
          message: "【" + this.msg.product.name + "】库存不足",
          type: "warning",
        });
      }
    }
  },
};
</script>

<style lang="less" scoped>
 .numPriceMsg{
      font-size:12px;
      color: #ff3333;
      margin-top: 10px;
      .numPricLaber{
        display: inline-block;
        color: #fff;
        background:#ff3333;;
        border-radius:2px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        padding: 1px 10px;
        margin: 0 10px 0 20px;
      }
    }
.itemSecBox{
  padding:2px 5px;
  width: auto;
  color:  #FF2D00;
  background: #f7ddd7 ;
  border:1px solid #FF2D00;
}
.ShopImg {
  min-width: 400px;
  color: #666;
  font-size: 12px;
  line-height: 17px;
  padding-left: 20px;
  position: relative;

  .stockTotal{
    position: absolute;
    bottom:6px;
    width: 90px;
    background: #636161;
    color: #fff;
    text-align: center;
  }
    .soldOut{
       display: block;
       width: 90px;
    height: 90px;
       z-index: 2;
       position:absolute;
    }
  .NOStocks {
    color: red;
  }
  img {
    display: inline-block;
    width: 90px;
    height: 90px;
    border: 1px solid #f0f0f0;
  }
  .otherInfo {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 92px;
    h3 {
      color: #333;
      font-size: 13px;
      line-height: 18px;
      font-weight: 600;
    }
    .gift {
      color: #ff9999;
    }
    .GetCoupon {
      font-size: 12px;
      color: #f43c38;
      background: none;
      border: none;
    }
    /deep/.el-dialog__header {
      padding-bottom: 0;
    }
  }
}
.el-popover.el-popper {
  padding-top: 0px;
  .titHead {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  .titMsg {
    text-align: center;
    line-height: 20px;
    color: #999;
    margin-bottom: 6px;
  }
  .clear {
    clear: both;
  }
  .coupon {
    margin-bottom: 20px;
    margin-right: 14px;
    width: 100%;
    height:100px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    display: flex;
    .coupon-left {
      width:80%;
      height:100px;
      position: relative;
      border: 1px solid #EEEEEE;
      background: #fff;
      font-size: 12px;
      color: #ff999999;
      border-radius: 5px;
      box-shadow: 0px 0px 1px rgba(0,0,0,0.3), 0px 0px 2px rgba(0,0,0,0.1) inset;
      border-right: none;
      .coupondes {
        padding:10px 20px;
        .coupondesTit{
          font-size: 13px;
          width: 100%;
          .numWraper {
            color: #f43c38;
            font-size:14px;
            font-weight: bold;
            .num {
              color: #f43c38;
              font-weight: 600;
              font-size:16px;
            }
          }
          .numMsg {
            color: #999;
            font-size: 12px;
            margin-left: 5px;
          }
        }
        .coupondesMsg{
          color: #333333;
          font-size:14px;
          line-height:30px;
          font-weight: bold;
        }
        .date {
          color: #333333;
          font-size:12px;
          margin-top:6px;
          text-align: left;
          width: 100%;
        }
      }
    }
    .coupon-right {
      cursor: pointer;
      background-color: #f43c38;
      flex: 1;
      height:100px;
      border-radius: 5px;
      padding: 8px 17px;
      line-height:19px;
      border: 1px solid #EEEEEE;
      box-shadow: 0px 0px 2px rgba(0,0,0,0.3), 0px 0px 2px rgba(0,0,0,0.1) inset;
      border-left: none;
      color: #fff;
    }
      
}

  .coupon:after,
  .coupon:before {
    box-shadow: 0 0px 3px rgb(146 146 152);
    transform: rotate(4deg);
    content: "";
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    z-index: 2;
    left: calc(80% - 10px);
    background: #fff;
    border-radius: 10px;
  }
  .coupon:after {
    bottom: -10px;
  }
  .coupon:before {
    top: -10px;
  }

  .coupon-left:after {
    content: "";
    position: absolute;
    top: 3px;
    display: block;
    width: 10px;
    height: 100%;
    right: -3px;
    z-index: 1;
    background-position: 0 0px;
    background-color: transparent;
    background-size: 10px 10px;
    background-image: radial-gradient(
      circle at right,
      #f43c38 6px,
      transparent 0
    );
  }
}
</style>
